import { Box, Button, Text } from '@otovo/rainbow';
import { getCentralUnits } from '@otovo/shared/cloudConfig/cloudConfig';
import { Cloud$BusinessUnitConfig } from '@otovo/shared/types/cloudApi';
import { Otovo$BreezeConfig } from '@otovo/shared/types/otovoweb';
import Link from 'next/link';

import {
  NextProps,
  ServerSidePropsContext,
} from '@otovo/shared/lib/next/types';
import BreezeHead from '../components/BreezeHead';
import CreateIframeCode from '../components/CreateIframeCode';
import { getServerSideProps as getDefaultProps } from '../config/getProps';

type Props = {
  centralUnits: Cloud$BusinessUnitConfig[];
};

const WelcomePage = ({ centralUnits }: Props) => {
  return (
    <>
      <BreezeHead title="Otovo Solar | Get your roofs solar potential" />
      <article className="mx-auto my-10 max-w-2xl p-5 px-3 text-center">
        <h1 className="mb-5 text-3xl font-bold">Welcome!</h1>
        <p className="mb-3 text-base">
          To search for an address, go to your local market:
        </p>
        <Box
          mb="7"
          boxShadow="0"
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
        >
          {centralUnits.map((businessUnit) => (
            <Link
              locale={businessUnit.locale}
              key={businessUnit.slug}
              href="/search"
              legacyBehavior
            >
              <Button as="a" variant="secondary" m="2">
                {businessUnit.company_name} {businessUnit.market.toUpperCase()}
              </Button>
            </Link>
          ))}
        </Box>

        <Box as="details">
          <summary className="text-base">
            <Text as="span" color="lilac_60">
              Create new partner iframe for the{' '}
              <strong>Otovo Address Search</strong>
            </Text>
          </summary>

          <CreateIframeCode markets={centralUnits} />
        </Box>
      </article>
    </>
  );
};

type ReturnType = { props: NextProps<Otovo$BreezeConfig> & Props };

export async function getServerSideProps(
  context: ServerSidePropsContext,
): Promise<ReturnType> {
  const { props } = await getDefaultProps(context);
  const centralUnits = getCentralUnits();
  return {
    props: {
      ...props,
      centralUnits,
    },
  };
}

export default WelcomePage;

import { Box } from '@otovo/rainbow';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { useState } from 'react';

type Props = {
  sourceCode: string;
};

const CodeSnippet = ({ sourceCode }: Props) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCopy = () => {
    setIsChecked(true);
    navigator.clipboard.writeText(sourceCode);
    setTimeout(() => {
      setIsChecked(false);
    }, 1000);
  };

  return (
    <Box position="relative">
      <Box
        as="button"
        top="1"
        right="1"
        position="absolute"
        backgroundColor={isChecked ? 'green_5' : 'peach_1'}
        onClick={handleCopy}
        borderRadius="2"
        borderColor={isChecked ? 'eGreen' : 'lilac_50'}
        borderWidth="1px"
        color={isChecked ? 'green_70' : 'lilac_80'}
        padding="2"
      >
        {isChecked ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            fill="none"
            stroke="currentColor"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-check"
          >
            <path d="M20 6L9 17 4 12" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            fill="currentColor"
            height="16"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M7 7V3a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1h-4v3.993c0 .556-.449 1.007-1.007 1.007H3.007A1.006 1.006 0 0 1 2 20.993l.003-12.986C2.003 7.451 2.452 7 3.01 7H7zm2 0h6.993C16.549 7 17 7.449 17 8.007V15h3V4H9v3zM4.003 9L4 20h11V9H4.003z" />
          </svg>
        )}
      </Box>
      <SyntaxHighlighter language="html">{sourceCode}</SyntaxHighlighter>
    </Box>
  );
};

export default CodeSnippet;

import {
  BodyM,
  Box,
  Button,
  Form,
  InputField,
  Label,
  ToggleField,
} from '@otovo/rainbow';
import { Cloud$BusinessUnitConfig } from '@otovo/shared/types/cloudApi';
import { useState } from 'react';
import CodeSnippet from './CodeSnippet';

const fallbackURL = 'solar.otovo.com'; // I trust process.env to be set, but Flow doesn't.

let baseURL = process.env.NEXT_PUBLIC_BREEZE_URL || `https://${fallbackURL}`;

if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview') {
  baseURL = `https://${process.env.NEXT_PUBLIC_VERCEL_URL || fallbackURL}`;
}

const getSource = (market, query, doNotTrack) => {
  if (!market) {
    return '';
  }

  // Brazil has a consumption slider enabled.
  const height = market === 'pt-br' ? 228 : 108;

  // We will SSR the iframe to avoid tracking. See search-ssr.js for details
  const searchPath = doNotTrack ? 'search-ssr' : 'search';

  return `
<!-- Otovo Address Search iframe start -->

<div style="min-height: ${height}px; position: relative; width:100%; z-index: 100;">
  <iframe
    id="otovo-iframe"
    class="otovo-address-search"
    frameborder="0"
    data-src="${baseURL}/${market}/${searchPath}?context=iframe${query}"
    height="${height}px"
    loading="lazy"
    width="100%"
    data-qa="iframe"
    title="otovo-address-search"
    style="border: 0px; position: absolute; overflow: hidden; left: 0"
    ></iframe>
</div>
<!-- Important: Include the script tag below! This is responsible for redirecting customers and ensuring correct attribution. If this is not included, the address search loading indicator will just spin indefinitely -->
<script type="text/javascript" src="${baseURL}/js/search.js" async></script>

<!-- Otovo Address Search iframe end -->
`;
};

function stringifyQueryParams(query) {
  return Object.entries(query)
    .map((el) => {
      if (el[1] && typeof el[1] === 'string') {
        return `${el[0]}=${el[1]}`;
      }

      return undefined;
    })
    .filter(Boolean)
    .join('&');
}

type Props = {
  markets: Cloud$BusinessUnitConfig[];
};

const CreateIframeCode = ({ markets }: Props) => {
  const [market, setMarket] = useState('');
  const [partner, setPartner] = useState('');
  const [campaign, setCampaign] = useState('');
  const [theme, setTheme] = useState('default');
  const [doNotTrack, setDoNotTrack] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const queryString = stringifyQueryParams({
    utm_source: partner?.trim(),
    utm_campaign: campaign?.trim(),
    theme,
    dnt: doNotTrack ? 'true' : undefined,
  });
  const sourceCode = getSource(
    market,
    queryString ? `&${queryString}` : '',
    doNotTrack,
  ).trim();

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsVisible(true);
  };

  return (
    <Box mt="5" textAlign="left">
      <Label htmlFor="market" display="inline-block" mb="1">
        Select market*
      </Label>
      <Form onSubmit={handleSubmit}>
        <Box
          id="market"
          as="select"
          display="inline-block"
          width="100%"
          fontSize="3"
          padding="3"
          borderColor="lilac_25"
          borderRadius="1"
          required
          color="darkLillac"
          value={market}
          onChange={(event) => {
            if (!event.currentTarget.value) {
              setIsVisible(false);
            }

            setMarket(event.currentTarget.value);
          }}
        >
          <option value="">---</option>
          {markets.map((businessUnit) => (
            <option key={businessUnit.locale} value={businessUnit.locale}>
              {businessUnit.company_name} {businessUnit.market.toUpperCase()}
            </option>
          ))}
        </Box>
        <InputField
          mt="4"
          id="partner"
          label="Partner*"
          required
          value={partner || ''}
          onChange={(event) => setPartner(event.currentTarget.value)}
          labelDescription="This will set the utm_source=<partner>."
        />
        <InputField
          mt="4"
          id="campaign"
          label="Campaign"
          value={campaign || ''}
          onChange={(event) => setCampaign(event.currentTarget.value)}
          labelDescription="This will set the utm_campaign=<campaign> (optional)."
        />
        <ToggleField
          mt="4"
          id="theme"
          value={theme}
          label="Use white theme"
          onChange={(event) =>
            setTheme(event.currentTarget.checked ? 'white' : 'default')
          }
          labelDescription="Use this if the iframe will be embedded on a dark background"
        />
        <ToggleField
          mt="4"
          id="dnt"
          value={doNotTrack}
          label="Do not track"
          onChange={(event) => setDoNotTrack(event.currentTarget.checked)}
          labelDescription="Enabling this will disable iframe tracking (GTM)"
        />
        <Button mt="6" type="submit">
          Create
        </Button>
      </Form>
      <Box as="hr" my="7" color="lilac_20" mx="9" />
      {isVisible && (
        <>
          <BodyM fontWeight="bold">
            Copy this snippet and paste in your HTML:
          </BodyM>
          <CodeSnippet sourceCode={sourceCode} />
        </>
      )}
    </Box>
  );
};

export default CreateIframeCode;
